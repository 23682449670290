@font-face {
    font-family: 'Ubuntu';
    src: local('Ubuntu'), url(./assets/fonts/Ubuntu-Regular.ttf) format('truetype');
}

/* @font-face {
    font-family: 'Ubuntu';
    src: local('UbuntuBold'), url(./assets/fonts/Ubuntu-Bold.ttf) format('truetype');
} */

html,
body,
#root {
    font-family: 'Ubuntu', sans-serif;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    cursor: none;
}

.label > div {
    font-family: 'Ubuntu', sans-serif;
    position: absolute;
    background: #00000088;
    color: white;
    padding: 15px;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 30px;
    user-select: none;
}

.annotation {
    /* cursor: pointer; */
    outline: none;
    border: none;
    font-family: 'Ubuntu', sans-serif;
    font-size: 0.4rem;
    font-weight: 300;
    background: rgb(0, 0, 0, 0.6);
    color: #f0f0f0;
    padding: 0.275rem 0.4rem;
    border-radius: 0.5rem;
    letter-spacing: 1px;
    justify-content: center;
    align-items: center;

    /* Prevent text selection */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
